import * as utils from '@affixapi/utils';

import { ApiUrl, UseLive } from '@lib/types';
import { env } from '@lib/utils';

export const apiVersion = utils.sharedConsts.api.apiVersionLatest;

export const apiUrl = utils.lookup.getLocalValue<ApiUrl>('REACT_APP_API_URL');
export const authorizeUrl = `https://authorize.api.${env}.engineering.affixapi.com`;

export const useLive =
  utils.lookup.getLocalValue<UseLive>('REACT_APP_USE_LIVE') === 'true'; // env var here is a string unfortunately
