export const paramClientId = 'client_id'; // developer
export const paramRedirectUri = 'redirect_uri'; // redirect you after signup
export const paramScopes = 'scope'; // identity, tax
export const paramAppType = 'app_type'; // ssr, spa
export const paramMode = 'mode'; // the param of the mode, ie mode=tax, the `mode` part
export const paramProvider = 'provider'; // skip the `Select` page
export const paramSandbox = 'sandbox';
export const paramState = 'state';
export const paramAddAdmin = 'add_admin';
export const paramTags = 'tags'; // select only certain integrations
export const paramEmail = 'email'; // sales demo
