import React, { useState } from 'react';

import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import { authorize } from '@services/authorize';
import { ErrorMessage } from '@pages/Messages';
import { PageContext } from '@lib/types';
import styles from '@sass/Choose.module.scss';
import sharedStyles from '@sass/shared.module.scss';

export const Choose = ({
  addAdmin,
  client,
  email,
  entities,
  handleAuthorizeResponse,
  mode,
  prevStep,
  provider,
  redirectUri,
  sandbox,
  scopes,
  sessionKey,
  state,
}: {
  addAdmin: NonNullable<PageContext['addAdmin']>;
  client: NonNullable<PageContext['client']>;
  email: PageContext['email'];
  entities: NonNullable<PageContext['entities']>;
  mode: NonNullable<PageContext['mode']>;
  provider: NonNullable<PageContext['provider']>;
  redirectUri: NonNullable<PageContext['redirectUri']>;
  scopes: NonNullable<PageContext['scopes']>;
} & Pick<
  PageContext,
  'handleAuthorizeResponse' | 'prevStep' | 'sandbox' | 'sessionKey' | 'state'
>): JSX.Element => {
  if (!provider) throw new Error('no provider');

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [entityKey, setEntityKey] = useState<null | number>(null);

  const { client_id: clientId } = client;

  const choose = async (i: number): Promise<void> => {
    const chosen = entities[i]['id'];

    setEntityKey(i);
    setError(undefined);
    setLoading(true);

    try {
      const res = await authorize({
        addAdmin,
        chosen,
        clientId,
        email,
        mode,
        providerId: provider.id,
        redirectUri,
        sandbox,
        scopes,
        sessionKey,
        state,
        step: 'CHOSEN',
      });

      handleAuthorizeResponse(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(
        err.message || 'Unexpected error has occurred. Please try again.'
      );
    }

    setLoading(false);
  };

  const accountsList = entities.map(
    (account, i): JSX.Element => (
      <button
        className={[
          styles.accountContainer,
          'ld-ext-right',
          entityKey === i && loading && 'running',
        ].join(' ')}
        disabled={loading}
        key={i}
        onClick={() => choose(i)} // eslint-disable-line @typescript-eslint/no-misused-promises
        tabIndex={0}
        type="button"
      >
        <span className={styles.companyName}>{account.name}</span>
        <div className="ld ld-ring ld-spin" />
        {!loading && <FiArrowRight />}
      </button>
    )
  );

  return (
    <>
      <div onClick={() => prevStep()}>
        <FiArrowLeft aria-label="Go Back" className={sharedStyles.backIcon} />
      </div>

      <div className={styles.container}>
        <img alt="" className={sharedStyles.largeLogo} src={provider.logo} />
        <div className={styles.header}>Select the entity</div>
        <div className={styles.accountsContainer}>{accountsList}</div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </>
  );
};

export default withAuthorizeContext(Choose);
