import React from 'react';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import * as api from '@affixapi/api';

import { FiArrowLeft } from 'react-icons/fi';
import logo from '@logos/logo.svg'; // eslint-disable-line @typescript-eslint/no-unused-vars
import wordLogo from '@logos/wordmark.svg';
import styles from '@sass/Scopes.module.scss';
import sharedStyles from '@sass/shared.module.scss';
import { scopesConfig } from '@lib/scopes';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import { PageContext } from '@lib/types';

export const Scopes = ({
  client,
  mode,
  nextStep,
  prevStep,
  provider, // the integration
  scopes,
  setCurrentStep,
}: {
  mode: NonNullable<PageContext['mode']>;
  scopes: NonNullable<PageContext['scopes']>;
} & Pick<
  PageContext,
  'client' | 'nextStep' | 'prevStep' | 'provider' | 'setCurrentStep'
>): JSX.Element => {
  const { name } = client;

  const onContinue = (): void => {
    if (provider) {
      setCurrentStep(api.consts.stepMap.SIGN_IN);
    } else {
      nextStep();
    }
  };

  return (
    <>
      <div onClick={() => prevStep()}>
        <FiArrowLeft aria-label="Go Back" className={sharedStyles.backIcon} />
      </div>
      <div className={sharedStyles.container}>
        <div className={sharedStyles.header}>
          <div className={sharedStyles.logos}>
            <img alt="affixapi logo" src={wordLogo} />
          </div>
          <div className={sharedStyles.title}>
            {name} requests access to the following scopes
          </div>

          <div className={sharedStyles.seperator} />

          <div className={styles.main} />
          <div className={styles.scopes}>
            {(scopes as api.root.Scopes)
              .filter(Boolean)
              .map((scope: api.root.Scope) => (
                <div
                  className={styles.scopesBox}
                  data-testid="scopes"
                  key={scope}
                >
                  <div className={styles.bulletGroup}>
                    <div className={styles.iconGroup}>
                      {
                        React.createElement(scopesConfig[mode][scope]!.icon) // eslint-disable-line @typescript-eslint/no-non-null-assertion
                      }
                    </div>
                    <div className={styles.textGroup}>
                      <div className={styles.scopesHeader}>
                        {scopesConfig[mode][scope]!.displayName // eslint-disable-line @typescript-eslint/no-non-null-assertion
                          ? scopesConfig[mode][scope]!.displayName // eslint-disable-line @typescript-eslint/no-non-null-assertion
                          : _startCase(_camelCase(scope))}
                      </div>
                      <div className={styles.scopesSubheader}>
                        {
                          scopesConfig[mode][scope]!.scopeText // eslint-disable-line @typescript-eslint/no-non-null-assertion
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className={sharedStyles.seperator} />

        <div className={sharedStyles.footer}>
          <button
            className={sharedStyles.button}
            onClick={onContinue}
            type="submit"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default withAuthorizeContext(Scopes);
