import React from 'react';
import { createRoot } from 'react-dom/client';
import '@sass/index.css';
import * as Sentry from '@sentry/react';
import { App } from '@src/App';

import { env } from '@src/lib/utils';

Sentry.init({
  dsn:
    'https://383911cad78e8f71ae82d81c2d02e2af@o4507062600597504.ingest.de.sentry.io/4507062632054864' || // eu
    'https://b9e28912f8afa451fd0887c32f405c2a@o1102369.ingest.us.sentry.io/4506960618192896', // us
  environment: env,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],

  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev\.api\.affixapi\.com/,
    /^https:\/\/api\.affixapi\.com/,
    /^https:\/\/affixapi\.com/,
  ],

  tracesSampleRate: 0.05,
});

const container = document.getElementById('root');

const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
