import React, { useState } from 'react';
import _get from 'lodash/get';
import { FiArrowLeft } from 'react-icons/fi';
// import * as api from '@affixapi/api';

import wordLogo from '@logos/wordmark.svg';
import styles from '@sass/Mfa.module.scss';
import sharedStyles from '@sass/shared.module.scss';
import { authorize } from '@services/authorize';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import { PageContext } from '@lib/types';
import { ErrorMessage } from '@pages/Messages';

const TopBar = ({ text }: { text: React.ReactNode }) => (
  <div className={styles.sandbox}>{text}</div>
);

export const Mfa = ({
  addAdmin,
  client,
  email,
  handleAuthorizeResponse,
  mfaEmail,
  mfaType,
  mode,
  phoneNumber,
  prevStep,
  provider, // the integration
  redirectUri,
  sandbox,
  scopes,
  sessionKey,
  state,
}: {
  addAdmin: NonNullable<PageContext['addAdmin']>;
  client: NonNullable<PageContext['client']>;
  mode: NonNullable<PageContext['mode']>;
  provider: NonNullable<PageContext['provider']>;
  redirectUri: NonNullable<PageContext['redirectUri']>;
  scopes: NonNullable<PageContext['scopes']>;
} & Pick<
  PageContext,
  | 'email'
  | 'handleAuthorizeResponse'
  | 'mfaEmail'
  | 'mfaType'
  | 'phoneNumber'
  | 'prevStep'
  | 'provider'
  | 'sandbox'
  | 'sessionKey'
  | 'state'
>): JSX.Element => {
  const [mfaCode, setMfaCode] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const { client_id: clientId } = client;

  const buttonStyle = {
    backgroundColor: provider.color,
  };

  const onContinue = async (
    e: React.FormEvent<
      | HTMLButtonElement // if `onContinue` is on the `button`
      | HTMLFormElement // if `onContinue` is on the `form`
    >
  ): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    if (!mfaCode) setError('MFA Code required');

    if (mfaCode) {
      setError(null);

      try {
        const res = await authorize({
          addAdmin,
          clientId,
          email,
          mfaCode,
          mode,
          providerId: provider.id,
          redirectUri,
          sandbox,
          scopes,
          sessionKey,
          state,
          step: 'MFA',
        });

        setMfaCode(''); // clear MFA code so can be re-used (security challenge etc)

        handleAuthorizeResponse(res);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        const errorCode = _get(err, 'response.data.code');

        // @affixapi/api:src/errors.ts#authErrorCodes
        const authErrorCodes = [
          'incorrect_username_or_password',
          'incorrect_mfa_or_otp',
          'reauthentication_required', // cookies are now expired
          'additional_account_setup_required', // password reset, etc
          'captcha_challenge', // amazon type1 captcha challenge (currently unsolvable)
          'security_challenge', // a blend between captcha_challenge and additional_account_setup_required; requires user to confirm login via an sms/email and clicking a link and clicking `approve`
          'no_valid_accounts', // not used currently but reserved for future use
          'insufficient_permission', // not enough privileges (ie not an admin, or not enough provider scopes on a provider api token for the Affix scopes requested)
        ] as const;
        // const disableSubmitButtonCodes = api.error.authErrorCodes.filter((errCode) => {

        const disableSubmitButtonCodes = authErrorCodes.filter((errCode) => {
          const disableCodes = [
            'reauthentication_required', // cookies are now expired
            'additional_account_setup_required', // password reset, etc
            'captcha_challenge', // amazon type1 captcha challenge (currently unsovable)
          ];

          return disableCodes.includes(errCode);
        });

        if (disableSubmitButtonCodes.includes(errorCode))
          setDisableSubmit(true);

        setError(
          err.message || 'Unexpected error has occured. Please try again.'
        );
      }
    }

    setLoading(false);
  };

  const onMfaCodeChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const {
      currentTarget: { value },
    } = e;

    if (value) setError('');

    setMfaCode(value);
  };

  const getMfaText = (): string => {
    switch (mfaType) {
      case 'text_message' || 'voice':
        if (phoneNumber)
          return `Enter the code sent to (***) *** - ${phoneNumber}`;

        return 'Enter the code sent to your phone';

      case 'authenticator':
        return 'Enter the code from the authenticator app (Google Authenticator or Twilio Authy)';

      case 'email':
        if (mfaEmail) return `Enter the code sent to ${mfaEmail}`;

        if (provider.id === 'personio.de')
          return 'Personio recognises this as a new login and sent a code to your email. Please enter the code';

        return 'Enter the code sent to your email';

      default: {
        if ('mfaMessage' in provider) return provider.mfaMessage!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

        return 'Enter the code sent to your mfa device';
      }
    }
  };

  return (
    <>
      <div onClick={() => prevStep()}>
        <FiArrowLeft aria-label="Go Back" className={sharedStyles.backIcon} />
      </div>

      <div className={sharedStyles.container}>
        <div className={sharedStyles.header}>
          <div className={sharedStyles.logos}>
            <img alt="affixapi logo" src={wordLogo} />
          </div>
          <div className={sharedStyles.title}>{getMfaText()}</div>
          <div className={sharedStyles.seperator} />
          {sandbox && (
            <TopBar
              text={
                <span>
                  You are in sandbox mode.
                  <br />
                  <br />
                  Use: "1111" to sign in;
                  <br />
                  <br />
                  anything else for an authentication error.
                </span>
              }
            />
          )}
          <img alt="" className={sharedStyles.largeLogo} src={provider.logo} />
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={onContinue}>
            <input
              className={sharedStyles.input}
              id="mfa_code"
              onChange={onMfaCodeChange}
              placeholder="Code"
              type="text"
              value={mfaCode}
            />
            {error && (
              <ErrorMessage>
                <span>{error}</span>
              </ErrorMessage>
            )}

            <button
              className={[
                styles.button,
                'ld-ext-right',
                loading && 'running',
              ].join(' ')}
              disabled={loading || disableSubmit}
              style={buttonStyle}
              type="submit"
            >
              Continue
              <div className="ld ld-ring ld-spin" />
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default withAuthorizeContext(Mfa);
