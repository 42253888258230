import React, { useState } from 'react';
import * as api from '@affixapi/api';
import * as utils from '@affixapi/utils';
import { State } from '@affixapi/utils/shared-types/webhooks';

import { FiArrowLeft } from 'react-icons/fi';
import wordLogo from '@logos/wordmark.svg';
import sharedStyles from '@sass/shared.module.scss';
import styles from '@sass/SignIn.module.scss';
import { Disclaimer, ErrorMessage, WarningMessage } from '@pages/Messages';
import { authorize } from '@services/authorize';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import { PageContext } from '@lib/types';
import {
  cezanneClientId,
  deelClientId,
  deelScopesToRequest,
  employmentHeroClientId,
  env,
  oysterOAuthSlug,
  payfitClientId,
  xeroUKClientId,
} from '@lib/utils';

const assistedConnect: api.root.Providers = ['itrent', 'fourthhr'];

export const SignIn = ({
  addAdmin,
  client,
  email,
  handleAuthorizeResponse,
  mode,
  prevStep,
  provider,
  redirectUri,
  sandbox,
  scopes,
  sessionKey,
  setProvider,
  state,
}: {
  addAdmin: NonNullable<PageContext['addAdmin']>;
  client: NonNullable<PageContext['client']>;
  email: PageContext['email'];
  mode: NonNullable<PageContext['mode']>;
  provider: NonNullable<PageContext['provider']>;
  redirectUri: NonNullable<PageContext['redirectUri']>;
  scopes: NonNullable<PageContext['scopes']>;
} & Pick<
  PageContext,
  | 'handleAuthorizeResponse'
  | 'prevStep'
  | 'provider'
  | 'sandbox'
  | 'sessionKey'
  | 'setProvider'
  | 'state'
>): JSX.Element => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [domain, setDomain] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string | undefined>(null);

  const { client_id: clientId } = client;

  const onSignIn = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    // Username, password
    const requiredCredentialsFields: (null | string | undefined)[] = [
      username,
      password,
    ];

    // apiKey
    const requiredApiKeyFields: (null | string | undefined)[] = [apiKey];

    if (
      !requiredCredentialsFields.every(Boolean) &&
      !requiredApiKeyFields.every(Boolean)
    ) {
      setError('All input fields are required.');
      setLoading(false);
      return;
    }

    setError(null);

    try {
      const res = await authorize({
        addAdmin,
        apiKey,
        clientId,
        domain,
        email,
        mode,
        password,
        providerId: provider.id,
        redirectUri,
        sandbox,
        scopes,
        sessionKey,
        state,
        step: 'SIGN_IN',
        username,
      });

      handleAuthorizeResponse(res);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(
        err.message || 'An unexpected error has occured. Please try again.'
      );
    }

    setLoading(false);
  };

  const onChangeWrapper =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.FormEvent<HTMLInputElement>): void => {
      const {
        currentTarget: { value },
      } = e;

      setter(value);

      if (value) setError(null);
    };

  const buttonStyle = {
    backgroundColor: provider.color,
  };

  const calendly = (
    <>
      {/*
      <Disclaimer>
        <>
          To connect your system, add `systemsintegrator@affiapi.com` as an
          administrator to your account.
          <br />
          <br />
          Book a call with the Affix Customer Success team for assistance.
        </>
      </Disclaimer>
      */}
      <div style={{ height: '650px' }}>
        <iframe
          frameBorder="1"
          height="100%"
          src="https://calendly.com/affixassistedconnectflow/call-with-graham-grieve-to-automate-manual-processes?hide_event_type_details=1&hide_gdpr_banner=1"
          width="99%"
        ></iframe>
      </div>
    </>
  );

  const apiInput = (
    <input
      className={sharedStyles.input}
      id="apiKey"
      onChange={onChangeWrapper(setApiKey)}
      placeholder={provider.apiKeyPlaceholder || 'API Key'}
      type="text"
      value={apiKey}
    />
  );

  const usernameInput = (
    <>
      <input
        className={sharedStyles.input}
        id="username"
        onChange={onChangeWrapper(setUsername)}
        placeholder={provider.userPlaceholder || 'Username'}
        type="text"
        value={username}
      />
    </>
  );

  const apiInputAndUsername = (
    <>
      {usernameInput}
      {apiInput}
    </>
  );

  // placeholder={providerDomainPlaceholder({ provider })}
  const domainInput = (
    <input
      className={sharedStyles.input}
      id="domain"
      onChange={onChangeWrapper(setDomain)}
      placeholder={provider.domainPlaceholder || 'Domain'}
      type="text"
      value={domain}
    />
  );

  const usernamePasswordInput = ({
    type = 'password',
  }: {
    // providers w official method + client_id + client_secret will use this input box
    type?: 'password' | 'text';
  }) => (
    <>
      {usernameInput}
      <input
        className={sharedStyles.input}
        id="password"
        onChange={onChangeWrapper(setPassword)}
        placeholder={provider.passwordPlaceholder || 'Password'}
        type={type}
        value={password}
      />
    </>
  );

  const domainUsernameAndPasswordInput = (
    <>
      {domainInput}
      {usernamePasswordInput({ type: 'password' })}
    </>
  );

  const domainAndApiKey = (
    <>
      {domainInput}
      {apiInput}
    </>
  );
  type OAuthPassThrough = Extract<
    api.root.Provider,
    | 'cezanne'
    | 'deel'
    | 'employment hero'
    | 'oysterhr'
    | 'parolla.ie'
    | 'payfit'
    | 'xero uk'
  >;
  const oAuthPassThroughProviders: OAuthPassThrough[] = [
    'cezanne',
    'deel',
    'xero uk',
    'employment hero',
    'oysterhr',
    'parolla.ie',
    'payfit',
  ];

  const stateMap: State = {
    clientId,
    redirectUri,
    sessionKey,
    ...(state ? { statePassThrough: state } : null),
  };
  const oAuthPassThrough = ({
    provider,
    scopes,
  }: {
    provider: OAuthPassThrough;
    scopes: api.root.Scopes;
  }) => {
    let url: string;

    switch (provider) {
      case 'cezanne': {
        const cezanneScopes = ['TODO']; // TODO
        url = `https://w3.cezanneondemand.com/CezanneOnDemand/OAuth/Authorize?client_id=${cezanneClientId}&scope=${cezanneScopes.join(
          ' '
        )}&response_type=code&redirect_uri=https://webhooks.${env}.engineering.affixapi.com/cezanne&state=${btoa(
          JSON.stringify(stateMap)
        )}`;
        break;
      }

      case 'deel': {
        url = `https://app.deel.com/oauth2/authorize?client_id=${deelClientId}&scope=${deelScopesToRequest(
          { scopes }
        ).join(
          ' '
        )}&response_type=code&redirect_uri=https://webhooks.${env}.engineering.affixapi.com/deel&state=${btoa(
          JSON.stringify(stateMap)
        )}`;
        break;
      }

      case 'employment hero': {
        url = `https://oauth.employmenthero.com/oauth2/authorize?client_id=${employmentHeroClientId}&response_type=code&redirect_uri=https://webhooks.${env}.engineering.affixapi.com/employment-hero&state=${btoa(
          JSON.stringify(stateMap)
        )}`;
        break;
      }

      case 'oysterhr': {
        if (!oysterOAuthSlug) throw new Error('oyster var is falsey');

        url = `https://app.oysterhr.com/oauth/authorize/${oysterOAuthSlug}?state=${btoa(
          JSON.stringify(stateMap)
        )}`;
        break;
      }

      case 'payfit': {
        const payfitScopes = ['TODO']; // TODO
        url = `https://oauth.payfit.com/authorize?client_id=${payfitClientId}&scope=${payfitScopes.join(
          ' '
        )}&response_type=code&redirect_uri=https://webhooks.${env}.engineering.affixapi.com/payfit&state=${btoa(
          JSON.stringify(stateMap)
        )}`;
        break;
      }

      case 'xero uk': {
        url = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${xeroUKClientId}&redirect_uri=https://webhooks.${env}.engineering.affixapi.com/xero&scope=openid profile email offline_access payroll.employees payroll.payruns payroll.payslip payroll.timesheets payroll.settings&state=${btoa(
          JSON.stringify(stateMap)
        )}`;
        break;
      }

      default:
        return usernamePasswordInput({ type: 'text' });
    }

    return (
      <>
        <a
          href={
            // https://snyk.io/blog/10-react-security-best-practices/
            utils.connect.validate.url({ isBackend: false, url })
          }
          onClick={() =>
            window.open(
              url,
              'popup',
              'resizable=yes, scrollbars=yes, titlebar=yes, width=700, height=800, top=10, left=10'
            ) as unknown as undefined
          }
          target={'popup'}
        >
          Connect HERE
        </a>
        {' and paste the result below'}
        <br />
        <br />
        {'Steps:'}
        <br />
        <br />
        {'- Connect your account'}
        <br />
        {'- Paste the value / key below'}
        <br />
        {'- Click Sign In'}
        <br />
        <br />
        {apiInput}
      </>
    );
  };

  // const inputFields = (mode: api.Mode, provider: api.Provider) => {
  const inputFields = ({
    mode,
    provider,
  }: {
    mode: api.root.Mode;
    provider: api.root.Provider;
  }) => {
    switch (mode) {
      case 'official': {
        // ask for client_id + client_secret
        //   i don't like the typecasting here, but it complains otherwise
        if (oAuthPassThroughProviders.includes(provider as OAuthPassThrough))
          return oAuthPassThrough({
            provider: provider as OAuthPassThrough,
            scopes: scopes as api.root.Scopes,
          });

        // despite asking for a username/password, we can get the
        // username/password without redirecting the user
        if (['iris cascade', 'personio.de'].includes(provider))
          return usernamePasswordInput({ type: 'text' });

        // ask for apiKey + service user ID
        if (['hibob'].includes(provider)) return apiInputAndUsername;

        // ask for apiKey + domain
        if (['bamboohr', 'staffology'].includes(provider))
          return domainAndApiKey;

        // assisted connect flow
        if (assistedConnect.includes(provider)) return calendly;

        return apiInput;
      }

      case 'xhr': {
        // ask for domain
        if (
          [
            'bamboohr',
            'iris cascade',
            'itrent',
            'peoplehr',
            'personio.de',
          ].includes(provider)
        )
          return domainUsernameAndPasswordInput;

        // assisted connect flow
        if (assistedConnect.includes(provider)) return calendly;

        return usernamePasswordInput({ type: 'password' });
      }

      default:
        return usernamePasswordInput({ type: 'password' });
    }
  };

  const renderFn = () => (
    <>
      {<img alt="" className={sharedStyles.largeLogo} src={provider.logo} />}
      {
        // don't show provider logo since we only have limited screen real estate
        // !assistedConnect.includes(provider.id) && (
        // <img src={provider.logo} className={sharedStyles.largeLogo} alt="" />
        // )
      }
      {sandbox && (
        <WarningMessage>
          <>
            You are in sandbox mode.
            <br />
            (if xhr mode) To sign in, enter:
            <br />
            - username: someusername
            <br />
            - password: somepassword
            <br />
            (if official mode) To sign in, enter:
            <br />
            - api key: abc123
            <br />
            anything else for an authentication error.
          </>
        </WarningMessage>
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={onSignIn}>
        {inputFields({ mode, provider: provider.id })}

        {error && (
          <ErrorMessage>
            <span>{error}</span>
          </ErrorMessage>
        )}
        {!assistedConnect.includes(provider.id) && (
          <button
            className={[
              styles.button,
              'ld-ext-right',
              loading && 'running',
            ].join(' ')}
            disabled={loading}
            style={buttonStyle}
            type="submit"
          >
            Sign In
            <div className="ld ld-ring ld-spin" />
          </button>
        )}
        {provider.id.includes('amazon') && (
          <>
            <Disclaimer>
              <>
                Amazon may send you a security alert.
                <br />
                Please click Accept if you receive such an alert, and try to
                sign in again
              </>
            </Disclaimer>
          </>
        )}
      </form>
    </>
  );

  return (
    <>
      <div
        onClick={() => {
          setProvider(undefined); // https://github.com/affixapi/connect/issues/5
          prevStep();
        }}
      >
        <FiArrowLeft aria-label="Go Back" className={sharedStyles.backIcon} />
      </div>

      <div className={sharedStyles.container}>
        <div className={sharedStyles.header}>
          <div className={sharedStyles.logos}>
            <img alt="affixapi logo" src={wordLogo} />
          </div>

          <div className={styles.header}>
            <div className={sharedStyles.title}>
              Authenticate with
              {` ${provider.displayName}`}
            </div>
          </div>

          <div className={sharedStyles.seperator} />
        </div>

        <div className={styles.main}>
          <div className={styles.content}>{renderFn()}</div>
        </div>
      </div>
    </>
  );
};

export default withAuthorizeContext(SignIn);
