import axios from 'axios';
import type { AxiosError } from 'axios';
import _get from 'lodash/get';
import * as api from '@affixapi/api';
import * as utils from '@affixapi/utils';

import { PageContextState } from '@lib/types';
import * as shared from '@services/shared';

const useLive = shared.useLive;
const authorizeUrl = shared.authorizeUrl;

export const authorize = async ({
  addAdmin,
  apiKey,
  chosen,
  clientId,
  domain,
  email,
  mfaCode,
  mode,
  password,
  providerId,
  redirectUri,
  sandbox,
  scopes,
  sessionKey,
  state,
  step = 'SIGN_IN',
  username,
}: {
  addAdmin: NonNullable<PageContextState['addAdmin']>;
  apiKey?: string;
  chosen?: string; // `chosen` id of selected entity
  clientId: api.v20230301.ValidateResponse['client_id']; // `clientId` validated from /connect/validate
  domain?: string;
  email?: string;
  mfaCode?: string;
  mode: NonNullable<PageContextState['mode']>;
  password?: string;
  providerId: NonNullable<PageContextState['provider']>['id'];
  redirectUri: NonNullable<PageContextState['redirectUri']>;
  scopes: NonNullable<PageContextState['scopes']>;
  step: Extract<api.root.Step, 'CHOSEN' | 'MFA' | 'SIGN_IN'>;
  username?: string;
} & Pick<
  PageContextState,
  'redirectUri' | 'sandbox' | 'sessionKey' | 'state'
>): Promise<api.v20230301.AuthorizeResponse> => {
  const validatedScopes = utils.connect.validate.scopes({
    isBackend: false,
    mode,
    scopes,
  });
  const requestBody: api.v20230301.AuthorizeRequest &
    Partial<{ sandbox: true }> = {
    add_admin: addAdmin,
    api_key: apiKey,
    chosen,
    client_id: clientId,
    domain,
    mfa_code: mfaCode,
    mode,
    password,
    provider: providerId,
    redirect_uri: redirectUri,
    scopes: validatedScopes,
    session_key: sessionKey,
    ...((state && { state }) || { state: null }),
    ...(sandbox && { sandbox }),
    ...(email && { email }),
    step, // response -> CHOOSE; request -> CHOSEN
    username,
  };

  if (!useLive) {
    switch (step) {
      case 'MFA': {
        if (providerId === 'personio.de') {
          const continueFlow = Math.random() < 0.75;

          if (continueFlow) {
            const showSecurityChallenge = Math.random() < 0.5;

            if (showSecurityChallenge)
              return <api.v20230301.AuthorizeResponse>{
                client_id: clientId,
                next: 'MFA',
                next_context: {
                  mfa_type: 'email',
                },
              };

            return <api.v20230301.AuthorizeResponse>{
              client_id: clientId,
              next: 'MFA',
              next_context: {
                mfa_type: 'authenticator',
              },
            };
          }
        }

        return <api.v20230301.AuthorizeResponse>{
          client_id: clientId,
          next: 'CHOOSE',
          next_context: {
            entity: [
              { id: '123', name: 'yodawg' },
              { id: '999', name: 'dawghi ltd.' },
              { id: '555', name: 'api programming services inc' },
              { id: '111', name: 'filter coffee company of ireland ltd.' },
              { id: '888', name: 'finn dawg computer services ltd.' },
            ],
          },
        };
      }

      case 'CHOSEN':
        return <api.v20230301.AuthorizeResponse>{
          client_id: clientId,
          next: null,
          redirect_to: state
            ? `http://localhost:3000/?authorization_code=99999&state=${state}`
            : `http://localhost:3000/?authorization_code=99999`,
        };

      case 'SIGN_IN':
        return <api.v20230301.AuthorizeResponse>{
          client_id: clientId,
          next: 'MFA',
          next_context: { mfa_type: 'text_message', phone_number: '5555' },
        };
    }
  }

  try {
    // call our backend
    const { data } = await axios.request({
      data: requestBody,
      method: 'POST',
      url: `${authorizeUrl}/connect/authorize`, // path here not needed, but for cypress interception
    });

    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.isAxiosError) {
      const axiosErr = err as AxiosError;
      const { response: axiosErrPayload } = axiosErr;

      const errMessage = _get(
        axiosErrPayload,
        'data.message',
        'An unexpected error occurred, try again!'
      ) as string;

      throw new Error(errMessage);
    }
    throw err;
  }
};
