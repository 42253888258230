import * as api from '@affixapi/api';
import * as utils from '@affixapi/utils';

import type * as ReactIcons from 'react-icons';

type IconType = ReactIcons.IconType;

// https://react-icons.github.io/react-icons/
import {
  FiBriefcase,
  FiCommand,
  FiShoppingCart, // eslint-disable-line @typescript-eslint/no-unused-vars
  FiTrendingUp, // eslint-disable-line @typescript-eslint/no-unused-vars
  FiTruck, // eslint-disable-line @typescript-eslint/no-unused-vars
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { FaCoffee } from 'react-icons/fa';
import { FaDollarSign, FaGlobe, FaMoneyCheckDollar } from 'react-icons/fa6';
import {
  LuCalendarCheck,
  LuCalendarDays,
  LuCalendarSearch,
} from 'react-icons/lu';
import { RxAccessibility } from 'react-icons/rx';
// import { IoReceiptOutline } from 'react-icons/io5';

const iconSelector = (k: api.root.Scope): IconType => {
  switch (k) {
    case '/2023-03-01/official/company':
    case '/2023-03-01/xhr/company':
      return FiBriefcase;
    case '/2023-03-01/official/employee':
    case '/2023-03-01/xhr/employee':
      return FiUser;
    case '/2023-03-01/official/employees':
    case '/2023-03-01/xhr/employees':
      return FiUsers;
    case '/2023-03-01/official/groups':
    case '/2023-03-01/xhr/groups':
      return FaGlobe;
    case '/2023-03-01/official/identity':
    case '/2023-03-01/xhr/identity':
      return RxAccessibility;
    case '/2023-03-01/official/time-off-balances':
    case '/2023-03-01/xhr/time-off-balances':
      return LuCalendarSearch;
    case '/2023-03-01/official/time-off-entries':
    case '/2023-03-01/xhr/time-off-entries':
      return LuCalendarCheck;
    case '/2023-03-01/official/timesheets':
    case '/2023-03-01/xhr/timesheets':
      return LuCalendarDays;
    case '/2023-03-01/official/work-locations':
    case '/2023-03-01/xhr/work-locations':
      return FaCoffee;
    case '/2023-03-01/xhr/payruns':
      return FaMoneyCheckDollar;
    case '/2023-03-01/xhr/payruns/:payrun_id':
      return FaDollarSign;

    default:
      return FiCommand; // fallback, but let's fill in a default
  }
};

const addIconToScopeConfig = (
  scopeConfig:
    | (typeof utils.connect.scopes.scopesConfig)['official']
    | (typeof utils.connect.scopes.scopesConfig)['xhr']
) =>
  Object.entries(scopeConfig).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: {
        ...v,
        icon: iconSelector(k as api.root.Scope),
      },
    }),
    {}
  );

// note, this doesn't need to be replaced or typed more strongly w/
// utils.connect.scopes.ScopesConfig; it's fine we get the shape, the specific
// type doesn't matter here as the const comes from utils; we are just tacking
// on an icon selector
// | 'employee'
export const scopesConfig: {
  [k in Extract<api.root.Mode, 'official' | 'xhr'>]: {
    [k in api.root.Scope]?: {
      displayName?: string;
      icon: IconType;
      scopeText: string;
    };
  };
} = {
  official: addIconToScopeConfig(utils.connect.scopes.scopesConfig['official']),
  xhr: addIconToScopeConfig(utils.connect.scopes.scopesConfig['xhr']),
};
