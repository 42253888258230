import React, { useState } from 'react';
import * as utils from '@affixapi/utils';

import { FiArrowLeft, FiSearch } from 'react-icons/fi';
import { providersFor } from '@lib/providers';
import styles from '@sass/Select.module.scss';
import sharedStyles from '@sass/shared.module.scss';
import wordLogo from '@logos/wordmark.svg';
import { withAuthorizeContext } from '@pages/AuthorizeContext';
import { PageContext } from '@lib/types';

export const Select = ({
  client,
  mode,
  nextStep,
  prevStep,
  sandbox,
  setProvider,
  tags,
}: {
  mode: NonNullable<PageContext['mode']>;
} & Pick<
  PageContext,
  'client' | 'nextStep' | 'prevStep' | 'sandbox' | 'setProvider' | 'tags'
>): JSX.Element => {
  const { client_id: clientId } = client;

  const onClick = (
    provider: utils.sharedTypes.connect.ProviderConfigWithLogo
  ) => {
    setProvider(provider);

    nextStep();
  };

  // allow user to press the enter/return key to 'click' the provider
  const onEnter =
    // eslint-disable-next-line @typescript-eslint/ban-types
    (cb: Function) => (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key !== 'Enter') return;

      e.preventDefault();
      e.stopPropagation();
      cb();
    };

  const [searchInput, setSearchInput] = useState('');

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = e;

    setSearchInput(value);
  };

  let filteredProviders = providersFor({
    mode,
    opts: {
      clientId,
      sandbox,
    },
  });

  if (tags && tags.length)
    filteredProviders = filteredProviders.filter(({ tags: providerTags }) =>
      providerTags.some((t) => tags.includes(t))
    );

  if (searchInput) {
    const lowercaseSearchInput = searchInput.toLowerCase();

    filteredProviders = filteredProviders.filter((provider) =>
      provider.displayName.toLowerCase().includes(lowercaseSearchInput)
    );
  }

  const providersToDisplay: JSX.Element | JSX.Element[] =
    0 < filteredProviders.length ? (
      filteredProviders.map(
        (provider): JSX.Element => (
          <div
            className={styles.provider}
            data-testid="provider"
            key={provider.id}
            onClick={() => onClick(provider)}
            onKeyDown={onEnter(() => onClick(provider))}
            role="button"
            tabIndex={0}
          >
            <img alt="" className={styles.providerLogo} src={provider.logo} />
            {provider.displayName}
          </div>
        )
      )
    ) : (
      <div className={styles.emptySearchResults}>
        No matching integrations found.
      </div>
    );

  return (
    <>
      <div onClick={() => prevStep()}>
        <FiArrowLeft aria-label="Go Back" className={sharedStyles.backIcon} />
      </div>

      <div className={styles.container}>
        <div className={sharedStyles.header}>
          <div className={sharedStyles.logos}>
            <img alt="affixapi logo" src={wordLogo} />
          </div>
          <div className={sharedStyles.title}>Select your integration</div>
        </div>

        <div className={sharedStyles.seperator} />

        <div className={styles.main}>
          <div className={styles.searchBox}>
            <FiSearch className={styles.icon} />
            <input
              className={styles.input}
              onChange={onChange}
              placeholder="Search integrations"
              type="text"
              value={searchInput}
            />
          </div>
          <div className={styles.providers}>{providersToDisplay}</div>
        </div>
      </div>
    </>
  );
};

export default withAuthorizeContext(Select);
