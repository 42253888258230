import React, { createContext, useContext, useState } from 'react';
import _get from 'lodash/get';
import { v1 as uuidv1 } from 'uuid';
import * as api from '@affixapi/api';
import * as utils from '@affixapi/utils';

import { nextStepMap, prevStepMap } from '@lib/steps';
import * as types from '@lib/types';
import { PageContext, State } from '@lib/types';

const AuthorizeContext = createContext({});
export const useAuthorizeContext = (): Partial<State> =>
  useContext(AuthorizeContext);

export const AuthorizeProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<api.root.Step>(
    api.consts.stepMap.PREAMBLE
  );
  const [appType, setAppType] = useState<PageContext['appType']>('ssr'); // set a default if none provided
  const [mode, setMode] = useState<PageContext['mode']>(); // set a default if none provided
  const [redirectUri, setRedirectUri] = useState<PageContext['redirectUri']>();
  const [scopes, setScopes] = useState<PageContext['scopes']>();
  const [sessionKey, setSessionKey] =
    useState<PageContext['sessionKey']>(uuidv1());
  const [client, setClient] = useState<PageContext['client']>(
    {} as api.v20230301.ValidateResponse
  );
  const [provider, setProvider] =
    useState<utils.sharedTypes.connect.ProviderConfigWithLogo>();
  const [sandbox, setSandbox] = useState<PageContext['sandbox']>(false);
  const [state, setState] = useState<PageContext['state']>();
  const [phoneNumber, setPhoneNumber] = useState<PageContext['phoneNumber']>();
  const [mfaType, setMfaType] = useState<PageContext['mfaType']>(undefined);
  const [mfaEmail, setMfaEmail] = useState<PageContext['mfaEmail']>();
  const [entities, setEntities] = useState<PageContext['entities']>();
  const [addAdmin, setAddAdmin] = useState<PageContext['addAdmin']>();
  const [tags, setTags] = useState<PageContext['tags']>();
  const [email, setEmail] = useState<PageContext['email']>();

  const validateAndSetScopes = ({
    mode,
    scopes,
  }: {
    mode: api.root.Mode; // we have already validated mode
    scopes: string | string[];
  }): api.root.Scopes => {
    const mmode = api.consts.modes.find(
      (availableMode) => availableMode === mode
    );

    if (!mmode) throw new Error('mode is not set!'); // we have already validated mode, this should not be called

    const scopesToValidate = !Array.isArray(scopes)
      ? scopes.split(' ')
      : scopes;

    const scopesValidated = utils.connect.validate.scopes({
      isBackend: false,
      mode: mmode,
      scopes: scopesToValidate,
    });

    setScopes(scopesValidated);

    return scopesValidated;
  };

  const nextStep = (): void => {
    setCurrentStep(nextStepMap[currentStep]);
  };

  const prevStep = (): void => {
    setCurrentStep(prevStepMap[currentStep]);
  };

  // forceSpa to avoid the stale props when there is a validation error in
  // Authorize.tsx useEffect
  const completeAuth = ({
    closed = false,
    error = 'Unexpected error',
    forceSpa = false,
    redirectTo,
  }: types.CompleteAuth): void => {
    const getSpaRedirect = (): string => {
      // if the flow ends up navigating to a page with a different origin
      const url =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.origin;

      // console.log({
      // windowLocation: window.location,
      // windowParentLocation: window.parent.location,
      // });
      // console.log({
      // documentLocationOrigin: document.location.origin,
      // documentReferrer: document.referrer,
      // redirectUri,
      // url,
      // });

      if (!redirectUri) throw new Error('no redirectUri!');

      if (redirectUri && !redirectUri.includes(url)) {
        console.warn(`url: ${url} did not match redirectUri: ${redirectUri}`);
        throw new Error('url did not match redirectUri!');
      }

      return url;
    };

    const handleSpa = (): void => {
      let message: {
        authorizationCode?: string;
        closed?: boolean;
        error?: string;
        name: string;
      } = {
        name: 'auth-message',
      };

      if (closed) {
        message = { ...message, closed: true };
      } else if (redirectTo) {
        const redirectToUri = new URL(redirectTo);
        const authorizationCode =
          redirectToUri.searchParams.get('authorization_code');

        if (!authorizationCode)
          throw new Error('did not receive an authorization_code back!');

        const state = redirectToUri.searchParams.get('state');

        message = {
          ...message,
          ...(authorizationCode && { authorizationCode }),
          ...(state && { state }),
        };
      } else {
        message = { ...message, error };
      }

      const url = getSpaRedirect();

      window.parent.postMessage(message, url);
    };

    if (appType === 'spa' || forceSpa) handleSpa();

    if (appType === 'ssr' && redirectTo) window.location.href = redirectTo;

    if (!redirectTo) throw new Error('dont have a redirectTo!');
  };

  const handleAuthorizeResponse = (
    res: api.v20230301.AuthorizeResponse
  ): void => {
    const {
      next,
      next_context: nextContext,
      redirect_to: redirectTo,
    } = utils.connect.validate.authorizeResponseAuthComplete({
      isBackend: false,
      res,
    });

    if (next) {
      if (next === 'MFA') {
        const number = _get(nextContext, 'phone_number');
        if (number) setPhoneNumber(number);

        const mfaEmail = _get(nextContext, 'email');
        if (mfaEmail) setMfaEmail(mfaEmail);

        const mfaType = _get(nextContext, 'mfa_type');
        if (mfaType) setMfaType(mfaType);
      }

      if (next === 'CHOOSE') {
        const entities = _get(nextContext, 'entity');
        if (entities) setEntities(entities);
      }

      setCurrentStep(next);
    }

    if (!next && redirectTo) completeAuth({ redirectTo });
  };

  return (
    <AuthorizeContext.Provider
      value={((): PageContext => {
        // while not ideal, this is the only way to get this typed, as angle brackets are not supported in TSX
        const value: PageContext = {
          addAdmin,
          appType,
          client,
          completeAuth,
          currentStep,
          email,
          entities,
          handleAuthorizeResponse,
          mfaEmail,
          mfaType,
          mode,
          nextStep,
          phoneNumber,
          prevStep,
          provider,
          redirectUri,
          sandbox,
          scopes,
          sessionKey,
          setAddAdmin,
          setAppType,
          setClient,
          setCurrentStep,
          setEmail,
          setEntities,
          setMfaEmail,
          setMfaType,
          setMode,
          setPhoneNumber,
          setProvider,
          setRedirectUri,
          setSandbox,
          setSessionKey,
          setState,
          setTags,
          state,
          tags,
          validateAndSetScopes,
        };
        return value;
      })()}
    >
      {children}
    </AuthorizeContext.Provider>
  );
};

export const withAuthorizeContext =
  (
    Component: (
      arg0: utils.typeUtils.DeepNonNullable<PageContext>
    ) => JSX.Element
  ) =>
  (props: utils.typeUtils.DeepNonNullable<PageContext>) => (
    <AuthorizeContext.Consumer>
      {(value) => <Component {...props} {...value} />}
    </AuthorizeContext.Consumer>
  );
