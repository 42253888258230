import React from 'react';
import _get from 'lodash/get';
import { MdInfoOutline, MdWarningAmber } from 'react-icons/md';

import { GoAlert } from 'react-icons/go';
import styles from '@sass/Messages.module.scss';
import { useAuthorizeContext } from '@pages/AuthorizeContext';

export const ErrorMessage = ({
  children,
  omitSessionKey = false,
}: {
  children: JSX.Element | string;
  omitSessionKey?: boolean;
}): JSX.Element => {
  const context = useAuthorizeContext();
  const sessionKey = _get(context, 'sessionKey');

  return (
    <div className={`${styles.error} ${styles.container}`}>
      <div className={styles.icon}>
        <GoAlert size="16px" />
      </div>
      <div>
        <div>{children}</div>
        {!omitSessionKey && (
          <div className={styles.sessionKey}>
            Error Code:
            <br />
            <pre className={styles.code}>{sessionKey}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export const WarningMessage = ({ children }: { children: JSX.Element }) => (
  <div className={`${styles.warning} ${styles.container}`}>
    <div className={styles.icon}>
      <MdWarningAmber size="16px" />
    </div>
    <div>
      <div className={styles.message}>{children}</div>
    </div>
  </div>
);

export const NoticeMessage = ({ children }: { children: JSX.Element }) => (
  <div className={`${styles.notice} ${styles.container}`}>
    <div className={styles.icon}>
      <MdInfoOutline size="16px" />
    </div>
    <div>
      <div className={styles.message}>{children}</div>
    </div>
  </div>
);

export const Disclaimer = ({ children }: { children: JSX.Element }) => (
  <div className={styles.disclaimer}>
    <div className={styles.icon}>
      <MdInfoOutline size="16px" />
    </div>
    <div>
      <div className={styles.message}>{children}</div>
    </div>
  </div>
);
