import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import * as utils from '@affixapi/utils';

import { PageContext } from '@lib/types';
import Authorize from '@pages/Authorize';
import { AuthorizeProvider } from '@pages/AuthorizeContext';

export const App = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route
        element={
          <AuthorizeProvider>
            {((props: utils.typeUtils.DeepNonNullable<PageContext>) => (
              <Authorize {...props} />
            ))({} as utils.typeUtils.DeepNonNullable<PageContext>)}
          </AuthorizeProvider>
        }
        path="*"
      />
    </Routes>
  </Router>
);
